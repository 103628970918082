<template>
  <loader-overlay
    :loading="(sending || validating) && sent"
    :color="'yellow'"
  >
    <div
      v-if="!verified"
    >
      <sms-signature-form
        :input-label="'remoteSignature.otp.form.label'"
        :button-label="'remoteSignature.otp.form.submit'"
        @submit="validateCode"
      >
        <template #notifications>
          <div>
            <p
              v-show="sent && !invalidCode"
              class="alert alert-success text-center"
            >
              {{$t('remoteSignature.otp.form.messageSent', { phone: phoneNumber })}}
            </p>
            <p
              v-show="invalidCode"
              class="alert alert-danger text-center"
            >
              {{$t('remoteSignature.otp.form.validationFailed')}}
            </p>
          </div>
        </template>

        <b-btn
          :variant="(sent) ? 'secondary' : 'primary'"
          :disabled="cooldown || sending"
          size="md"
          @click.prevent="issueCode"
        >
            {{$t((sent) ? 'remoteSignature.otp.form.sendNewCode' : 'remoteSignature.otp.form.sendCode')}}
        </b-btn>
      </sms-signature-form>
    </div>

    <div
      v-else
    >
      <p class="alert alert-success text-center">
        {{$t('remoteSignature.otp.form.verifiedMessage')}}
      </p>
    </div>
  </loader-overlay>
</template>

<script>
import SmsSignatureForm from '../../Signature/Components/SmsSignatureForm.vue'

export default {
    name: 'OtpCode',
    components: {
        SmsSignatureForm
    },
    props: {
        sendOnMount: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            sent: false,
            verified: false,
            cooldown: false,
            sending: false,
            validating: false,
            invalidCode: false,
            phoneNumber: null
        }
    },
    mounted () {
        if (this.sendOnMount && window.sessionStorage.getItem('otpSmsWasSent') !== '1') {
            this.issueCode()
        }
    },
    methods: {
        issueCode () {
            this.cooldown = true
            this.sending = true
            this.invalidCode = false
            setTimeout(() => {
                this.cooldown = false
            }, 5000)

            this.$api.signatureSessions.sendOtp(this.$t('locale'))
                .then((response) => {
                    this.sent = true
                    this.phoneNumber = response.data.phoneNumber
                    window.sessionStorage.setItem('otpSmsWasSent', '1')
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.sending = false
                    })
                })
        },
        validateCode (code) {
            this.invalidCode = false
            this.validating = true

            this.$api.signatureSessions.validateOtp(code)
                .then(() => {
                    this.verified = true
                    this.$emit('verified')
                })
                .catch((error) => {
                    console.error(error)
                    this.invalidCode = true
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.validating = false
                    })
                })
        }
    }
}
</script>
