<template>
    <loader-overlay
        :loading="loading"
        :color="'yellow'"
    >
        <div v-if="!error" class="embed-responsive embed-responsive-4by3">
            <iframe class="embed-responsive-item mh-50vh" ref="documentIframe" :src="(show) ? src : undefined" />
        </div>
        <div v-else class="alert alert-danger">
            {{$t('remoteSignature.iframeError')}}
        </div>
    </loader-overlay>
</template>

<script>
export default {
    name: 'DocumentIframe',
    props: {
        src: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            show: false,
            loading: true,
            error: false
        }
    },
    watch: {
        src (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.show = false
                this.loading = true
                this.error = false
                this.$nextTick(() => {
                    this.show = true
                })
            }
        }
    },
    mounted () {
        this.$refs.documentIframe.addEventListener('load', this.loadListener)
        this.$refs.documentIframe.addEventListener('error', this.errorListener)
        this.show = true
    },
    beforeDestroy () {
        this.$refs.documentIframe.removeEventListener('load', this.loadListener)
        this.$refs.documentIframe.removeEventListener('error', this.errorListener)
    },
    methods: {
        loadListener (event) {
            this.loading = false
            this.error = false
            this.$emit('load', event)
        },
        errorListener (event) {
            this.loading = false
            this.error = true
            this.$emit('error', event)
        }
    }
}
</script>
