<template>
    <data-table
        class="table-sm-font"
        :responsive="true"
        :auto-update="false"
        :header="header"
        :loading="loading"
        :data="dataset"
        :buttons="buttons"
        :lang="$t('locale')"
        :paging="false"
        @detail="onDetail"
    />
</template>

<script>
export default {
    name: 'DocumentsDataTable',
    props: {
        dataset: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        header () {
            return [
                {
                    text: this.$t('remoteSignature.dataTable.title'),
                    data: 'document.title',
                    cellClassnames: ['font-weight-bold']
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'detail',
                    variant: 'primary',
                    text: 'Detail'
                }
            ]
        }
    },
    methods: {
        onDetail (row) {
            this.$emit('detail', row)
        }
    }
}
</script>
