<template>
    <div class="pb-5">
        <div class="container mb-5">
            <h1>{{$t('remoteSignature.heading')}}</h1>
            <p>{{$t('remoteSignature.perex')}}</p>
        </div>

        <div v-if="loading" class="container">
            <div class="bg-white p-5 shadow cyrrus-rounded">
                <loader-overlay :loading="loading">
                    <div class="py-5"></div>
                </loader-overlay>
            </div>
        </div>

        <div v-else class="container">
            <div v-if="!isOtpVerified">
                <h3>{{$t('remoteSignature.otp.heading')}}</h3>
                <p>{{$t('remoteSignature.otp.perex')}}</p>
                <div class="row">
                    <div class="col-12 col-md-8">
                        <otp-code
                            :send-on-mount="true"
                            @verified="isOtpVerified = true"
                        />
                    </div>
                </div>
            </div>

            <div v-else>
                <h3>{{$t('remoteSignature.documents.heading')}}</h3>
                <loader-overlay :loading="sessionLoading">
                    <div
                        v-if="!iframeUrl"
                    >
                        <div class="bg-white pt-3 pl-4 pr-4 pb-1 shadow cyrrus-rounded">
                            <documents-data-table
                                :dataset="completedDocuments"
                                :loading="sessionLoading"
                                @detail="onDetail"
                            />
                        </div>

                        <h3 class="mt-5">{{$t('remoteSignature.sms.heading')}}</h3>
                        <div class="row">
                            <div class="col-12 col-md-8 col-lg-6">
                                <div v-if="session" class="bg-white p-4 shadow cyrrus-rounded">
                                    <signature-code
                                        v-if="!session.isLocallySigned"
                                        :send-on-mount="false"
                                        @verified="onSignatureVerified"
                                    />
                                    <div
                                        v-else
                                        class="alert alert-success"
                                    >
                                        {{$t('remoteSignature.alreadySigned')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="iframeUrl"
                        class="bg-white p-3 shadow cyrrus-rounded"
                    >
                        <h3 class="mb-2">{{iframeTitle}}</h3>
                        <p>
                            <b-btn
                                variant="primary"
                                size="sm"
                                @click.prevent="onCloseIframe"
                            >
                                {{$t('remoteSignature.close')}}
                            </b-btn>
                        </p>
                        <div class="bg-dark">
                            <document-iframe
                                :src="iframeUrl"
                            />
                        </div>
                    </div>
                </loader-overlay>
            </div>
        </div>
    </div>
</template>

<script>
import DocumentsDataTable from './Components/DocumentsDataTable.vue'
import OtpCode from './Components/OtpCode.vue'
import DocumentIframe from './Components/DocumentIframe.vue'
import SignatureCode from './Components/SignatureCode.vue'

export default {
    name: 'RemoteSignature',
    components: {
        OtpCode,
        DocumentsDataTable,
        DocumentIframe,
        SignatureCode
    },
    data () {
        return {
            loading: true,
            isOtpVerified: false,
            sessionLoading: true,
            session: null,
            iframeUrl: null,
            iframeTitle: null
        }
    },
    computed: {
        completedDocuments () {
            return (this.session && this.session.completedDocuments) ? this.session.completedDocuments : []
        }
    },
    async mounted () {
        await this.loadOtpState()
    },
    watch: {
        isOtpVerified (newValue) {
            if (newValue) {
                this.loadSession()
            }
        }
    },
    methods: {
        onSignatureVerified () {
            this.$router.replace({ name: 'SignatureSaved' })
        },
        onDetail (row) {
            this.iframeUrl = this.$api.signatureSessions.getIframeUrl(row.documentData._id)
            this.iframeTitle = row.document.title
        },
        async loadOtpState () {
            this.loading = true
            try {
                await this.$api.signatureSessions.isOtpVerified()
                this.isOtpVerified = true
            } catch {
                this.isOtpVerified = false
            } finally {
                this.$nextTick(() => {
                    this.loading = false
                })
            }
        },
        async loadSession () {
            this.sessionLoading = true
            try {
                const response = await this.$api.signatureSessions.getSession()
                this.session = response.data
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('fillout.sessionLoadError'))
            } finally {
                this.$nextTick(() => {
                    this.sessionLoading = false
                })
            }
        },
        onCloseIframe () {
            this.iframeUrl = null
            this.iframeTitle = null
        }
    }
}
</script>
